body .flex-row {
  display: flex;
  flex-direction: row;
}

body .flex-col {
  display: flex;
  flex-direction: column;
}

body .align-items-center {
  align-items: center;
}

body .w100 {
  width: 100%;
}

body .m0 {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}

body .px10 {
  padding-left: 10px;
  padding-right: 10px;
}

body .px8 {
  padding-left: 8px;
  padding-right: 8px;
}

body .p15 {
  padding: 15px;
}

body .my10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

body .order-input {
  position: relative;
}

body .order-input i {
  position: absolute;
  color: #000;
  background: #bbb;
  border-radius: 50%;
  top: -18px;
  right: -10px;
  padding: 3px;
}

body .order-input i:hover {
  cursor: pointer;
  background: #969696;
}

body .red-label {
  color: red;
  margin-bottom: 10px;
}
